$publicPath:'';

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaa;
}
.player-demo * {
  box-sizing: border-box;
}

.video-box {
  width: 100%;
  height: 405px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #707070;
  position: relative;
}

.video-title {
  width: 100%;
  margin-top: 10px;

  & > .title {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 30px;
    color: #000000;
    opacity: 0.85;
    margin-bottom: 10px;
  }

  .title-createInfo {
    display: flex;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 12px;
    .user {
      margin-right: 5px;
      &::before {
        position: relative;
        top: 2px;
        content: "";
        margin-right: 5px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("~assets/img/icon/user-icon.png") no-repeat center/cover;
      }
    }
    .clock {
      &::before {
        position: relative;
        top: 2px;
        content: "";
        margin-right: 2px;
        display: inline-block;
        width: 14px;
        height: 14px;
        background: url("~assets/img/icon/clock-icon.png") no-repeat
          center/cover;
      }
    }
  }
}

.player-demo .main-content .m-right .play-content .img-wrap img {
  object-fit: cover;
}
.main-footer {
  display: block;
  height: 40px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.player-demo {
  min-height: 100%;
  .main-content {
    color: #666666;
    width: 100%;
    margin: 0 auto;
    padding-left: 64px;
    padding-right: 64px;
    .m-left {
      width: 100%;
      .vd-type {
        margin-top: 25px;
        .wrap {
          float: left;
          border: 1px solid #73777a;
        }
        a {
          font-size: 18px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
          width: 105px;
          height: 38px;
          display: block;
          float: left;
          line-height: 38px;
          cursor: pointer;
          &.active {
            background: #30adf2;
          }
        }
      }

      .video-description {
        margin-top: 26px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.85);
        p {
          text-indent: 2em;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
          opacity: 1;
        }
      }
    }
    .m-right {
      width: 100%;
      .play-list {
        margin-top: 20px;
        font-size: 20px;
        color: #fff;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 20px;
        line-height: 24px;
      }
      .play-content {
        height: 650px;
        .item {
          margin-bottom: 8px;
          cursor: pointer;
        }
        .img-wrap {
          float: left;
          height: 134px;
          position: relative;
          width: 178px;
          padding-top: 10px;
          padding-bottom: 10px;

          img {
            display: block;
            width: 178px;
            height: 114px;
          }
          .video-time {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            background-color: rgba(0, 0, 0, 0.8);
            bottom: 15px;
            color: #fff;
            display: block;
            font-size: 12px;
            font-weight: bold;
            padding: 0 5px;
            position: absolute;
            right: 5px;
            text-align: right;
          }
          .play-cover {
            position: absolute;
            display: none;
            top: 10px;
            left: 0;
            right: 0;
            bottom: 10px;
            background: rgba(0, 0, 0, 0.3) url("~assets/img/play-btn.png")
              center center no-repeat;
            background-size: 22px 22px;
          }
          &:hover {
            .play-cover {
              display: block;
            }
          }
        }
        .con-wrap {
          width: calc(100% - 178px);
          height: 134px;
          float: right;
          padding: 10px;
          position: relative;
          .title {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            color: #333333;
            display: -webkit-box;
            font-size: 20px;
            letter-spacing: 0;
            line-height: 18px;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: normal;
            max-height: 92px;
            overflow: hidden;
          }
          .description {
            margin-top: 8px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: normal;
            max-height: 66px;
            overflow: hidden;
          }
          .author {
            color: #c3c5c6;
            font-size: 12px;
            letter-spacing: 0;
            margin-top: 8px;
            text-align: left;
          }
          .createInfo {
            display: flex;
            position: absolute;
            bottom: 0;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.65);
            margin-bottom: 12px;
            .user {
              margin-right: 5px;
              &::before {
                position: relative;
                top: 2px;
                content: "";
                margin-right: 5px;
                display: inline-block;
                width: 14px;
                height: 14px;
                background: url("~assets/img/icon/user-icon.png") no-repeat
                  center/cover;
              }
            }
            .clock {
              &::before {
                position: relative;
                top: 2px;
                content: "";
                margin-right: 2px;
                display: inline-block;
                width: 14px;
                height: 14px;
                background: url("~assets/img/icon/clock-icon.png") no-repeat
                  center/cover;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .player-demo .main-content {
    width: 100%;
    padding: 0 20px;
  }
  .player-demo .main-content .m-left {
    width: 100%;
  }
  .player-demo .main-content .m-right {
    width: 100%;
  }
  .player-demo .main-content .m-right .play-content .con-wrap {
    width: 185px;
  }
}
